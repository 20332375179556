import { sentryConfigs } from 'sentry.config';

export const environment = {
    // MAIN
    environment: 'stage3',
    production: false,
    // APIs
    adminAPI: 'https://stage3.gateway-api.dctr.app',
    crmAPI: 'https://stage3.gateway-api.dctr.app',
    doctorAPI: 'https://stage3.gateway-api.medvidi.com',
    patientAPI: 'https://stage3.gateway-api.medvidi.com',
    socketMedvidiAPI: 'https://stage3.socket.medvidi.com',
    socketDctrAPI: 'wss://stage3.socket.dctr.app',
    // HOSTS
    doctorHost: 'https://stage3.doc.medvidi.com',
    crmHost: 'https://stage3.crm.dctr.app',
    videoChatHost: 'https://stage3.dctr.app/v/',
    // SESSION
    sessionKey: 'dctrapp.stage3.api.sess',
    // INTERCOM
    intercomWorkspace: 'https://app.intercom.com/a/inbox/bpst9686',
    intercomWorkspaceId: 'bpst9686',
    // TWILIO CALLS
    connectActivitySid: 'WA774d06f6f36bfc74de891128988b6292',
    disconnectActivitySid: 'WA1697376f1a9665dc4a1a51fc93ded4d7',
    // STRIPE
    stripeToken:
        'pk_test_51JoutIJUokFKVK7naWM4RwULOSXjBZaG83AZ4k7noigzliYFHDjYGYOPXHUjC1ZkNzaRluKnUCHkOLnI0hinkQax00x9EthmJw',
    // GOOGLE
    googleTagManager: {
        'start.ezcareclinic.io': 'GTM-5G78WWS',
        'start.ezcareclinic.com': 'GTM-5G78WWS',
    },
    googlePlaceKey: 'AIzaSyCwDf7GCdc7-KRzF39DfxJBflRNhY5mHDg',
    // OTHER
    websiteCode: {
        default: 'start_ezcareclinic',
        'stage3.start.ezcareclinic.io': 'start_ezcareclinic',
        'stage3.start.ezcareclinic.com': 'start_ezcareclinic',
        'stage3.start.mangoclinic.com': 'start_mangoclinic',
    },
    clinicName: {
        default: 'EZCare Clinic',
        start_ezcareclinic: 'EZCare Clinic',
        mmjdoctor: 'MMJ Doctor',
        esacare: 'ESAcare',
        medvidi: 'MEDvidi',
        start_mangoclinic: 'Mango Clinic',
        mdberry: 'MDBerry',
    },
    // SENTRY
    ...sentryConfigs,
};
